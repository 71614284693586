import React from 'react';
import styled from "styled-components";
import className from 'classnames';

import {
  aliBuy,
  wxBuy,
  renewWx,
  renewAli,
  checkOrder,
} from '../../http';
import constant from '../../constant';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import withI18next from '../../components/withI18next';
import Alert from '../../components/alert';
import contract from '../../contract.pdf';
import { getLink } from '../../utils';

const OHeader = styled.div.attrs({ id: 'product-header' })`
  height: 400px;
  margin: 0 auto;
  margin-top: -80px;
  max-width: 1920px;
  padding-top: 150px;
  background: linear-gradient(to bottom, #708BFF, #8BA1FF);
  canvas {
    position: absolute;
    top: 0;
    bottom: 0;
  }
`

const OContent = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  .order-card {
    width: 472px;
    height: 490px;
    border-radius: 8px;
    box-shadow: 0px 9px 10px 0px rgba(0,0,0,0.1);
    transform: translateY(-126px);
    background: #fff;
    .card-header {
      height: 175px;
      line-height: 150px;
      font-size: 26px;
      border: none;
      background: center / contain url(${constant.world_map}) no-repeat;
    }
    .order-list {
      .order-item {
        height: 55px;
        line-height: 55px;
        .order-pay {
          font-size: 14px;
          .cost {
            color: #E24C4C;
          }
        }
      }
      > ul {
        margin-bottom: 0;
      }
      ul > li.order-item {
        position: relative;
        padding-left: 70px;
        padding-right: 45px;
        box-shadow: 0px 2px 14px 0px rgba(39,52,125,0.05);
        &::before {
          content: " ";
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 38px;
          width: 9px;
          background: ${constant.active};
          border-radius: 0px 5px 5px 0px;
        }
      }
      .info {
        padding-left: 70px;
        padding-right: 45px;
        > div {
          line-height: 60px;
          height: 60px;
          .tag {
            font-size: 16px;
          }
        }
        .discount {
          border-bottom: 1px dashed #8992BF;
        }
        .sum {
          .order-cost {
            height: 40px;
            min-width: 174px;
            line-height: 40px;
            padding: 0 10px;
            border-radius: 46px;
            border: 1px solid #EFAA3C;
            .cost {
              font-size: 24px;
              color: #FFB329;
              font-weight: bolder;
            }
          }
        }
      }
    }
  }
  .pay-content {
    padding-top: 45px;
    flex-basis: 430px;
    margin-left: 95px;
    .qr_code {
      display: block;
      margin: 0 auto;
      width: 245px;
      height: 245px;
    }
    .pay-list {
      .pay-way {
        margin-bottom: 34px;
        .pay-item {
          box-sizing: content-box;
          position: relative;
          line-height: 39px;
          height: 39px;
          width: 160px;
          font-size: 14px;
          border-radius: 19px;
          box-shadow: 0px 9px 10px 0px rgba(0,0,0,0.1);
          cursor: pointer;
          &.active {
            background: center / contain url(${constant.pay_select}) no-repeat;
            /* border: 1px solid #FFB329;
            overflow: hidden;
            &::after {
              top: 0;
              right: 0;
              position: absolute;
              content: " ";
              width: 32px;
              height: 24px;
              background: #FFB329 top right url(${constant.select}) no-repeat;
            } */
          }
        }
      }
      .agreement {
        .check {
          height: 14px;
          width: 14px;
          vertical-align: middle;
          cursor: pointer;
          border: 1px solid;
          &.active {
            background: center url(${constant.check}) no-repeat;        
          }
        }
        .filename {
          color: ${constant.active};
        }
      }
      .buy-btn {
        /* background:linear-gradient(360deg,rgba(77,115,245,1) 0%,rgba(107,139,250,1) 100%); */
        /* background: #708BFF; */
        /* box-shadow:0px 6px 4px 0px rgba(0,0,0,0.2),0px 2px 1px 0px rgba(59,97,225,1); */
        border-radius:24px;
        border:1px solid rgba(69,109,241,1);
        padding: 10px 50px;
        cursor: pointer;
      }
    }
    > p {
      font-size: 14px;
      font-weight: 500;
      color: #1F263E;
    }
    
  }
`;

const prices = [0, 10000, 20000, 50000, 100000];
const game_type = ["DOTA2", "LOL", "CSGO", "王者荣耀"];
const LEVEL_NUM = 5;
const product_id_map = new Map([ // key-位置 value- product_id
  [1, 1], [2, 2], [3, 3], [4, 4], [5, 19],
  [6, 5], [7, 6], [8, 7], [9, 8], [10, 18],
  [11, 9], [12, 10], [13, 11], [14, 12], [15, 20],
  [16, 13], [17, 14], [18, 15], [19, 16], [20, 17]
])

class Order extends React.Component {
  constructor(props) {
    super(props);
    const { t } = props;
    const state = props.location.state;
    this.state = {
      payment: 0,
      product_ids: '',
      isScan: false,
      accept: true,
      orderItems: [],
      qrCode: '',
      orderId: '',
      checkId: '',
      alert: '',
      tradeType: '',
    }
    if (!state) {
      return;
    } else if ('type' in state) {
      const orderItems = state.id.map(it => {
        // const type = game_type[Math.floor((it - 1) / LEVEL_NUM)];
        const type = t(`provideData.${[Math.floor((it - 1) / LEVEL_NUM)]}.title`, { returnObjects: true });
        const orderType = (it - 1) % LEVEL_NUM;
        const order = t('price.project.type_list', { returnObjects: true });
        return {
          name: `${type}-${order[orderType]}`,
          price: prices[orderType],
        };
      });
      this.state.orderItems = orderItems;
      this.state.product_ids = state.id.join(',');
      this.state.tradeType = state.type;
      this.state.orderId = state.orderId;
      this.state.checkId = setInterval(() => this.checkPayment(state.orderId), 2000);
    } else if ('qrCode' in state) {
      const orderItems = state.id.map(it => {
        const type = game_type[Math.floor((it - 1) / LEVEL_NUM)];
        const orderType = (it - 1) % LEVEL_NUM;
        const order = t('price.project.type_list', { returnObjects: true });
        return {
          name: `${type}-${order[orderType]}`,
          price: prices[orderType],
        };
      });
      this.state.orderItems = orderItems;
      this.state.isScan = true;
      this.state.product_ids = state.id.join(',');
      this.state.qrCode = state.qrCode;
      this.state.payment = state.payment;
    }
  }
  switchType(type, val) {
    this.setState({
      [type]: val
    })
  }
  checkPayment(id) {
    const { t } = this.props;
    checkOrder(id).then(
      (res) => {
        if (res.status === 0) {
          if (res.data.status === 2) {
            const { checkId } = this.state;
            this.alert(t('price.order.success'), 'success');
            clearInterval(checkId);
            setTimeout(() => {
              window.location = getLink('/usercenter/#2');
            }, 1000);
          }
        }
      })
  }
  componentWillUnmount() {
    const { checkId } = this.state;
    clearInterval(checkId);
  }
  alert(message, type) {
    this.setState(
      { alert: { type, message } },
      () => setTimeout(() => this.setState({ alert: '' }), 2000)
    );
  }
  sendFunc() {
    const { payment, tradeType } = this.state;
    const buy = {
      renew: [renewWx, renewAli],
      buy: [wxBuy, aliBuy],
    };
    return buy[tradeType][payment];
  }
  handleSubmit() {
    const { product_ids, payment } = this.state;
    if (!product_ids) return;
    let new_ids = [];
    let idArrs = product_ids.split(',');
    idArrs.map((id)=>{
      new_ids.push(product_id_map.get(parseInt(id)));
    });
    const newids = new_ids.join(',');
    const send = this.sendFunc();
    send(newids).then((res) => {
      if (res.status === 0) {
        this.setState({
          isScan: true,
          qrCode: res.data.pay_qrcode,
          orderId: res.data.id,
          checkId: setInterval(() => {
            this.checkPayment(res.data.id)
          }, 2000)
        });
        if (payment === 1) {
          window.open(res.data.pay_qrcode, '_blank');
        }
      } else {
        this.alert(res.msg, 'danger');
      }
    }).catch((err) => {
      const { data } = err;
      this.alert(data.msg, 'danger');
    })

  }

  render() {
    const { t, locale } = this.props;
    const { payment, isScan, accept, orderItems, qrCode, alert, product_ids } = this.state;
    const payText = t('price.order.pay', { returnObjects: true });
    return (
      <Layout type="order">
        <SEO title="Order" />
        {alert && <Alert info={alert} />}
        <OHeader>
          <h1 className="text-center text-white">{t('price.order.title')}</h1>
        </OHeader>
        <OContent className="d-flex">
          <div className="order-card">
            <div className="card-header text-center">
              <img className="mr-4" src={constant.order} alt=" " />
              {t('price.order.card.title')}
            </div>
            <div className="order-list">
              <ul>
                {orderItems.map(ord => (
                  <li className="order-item d-flex justify-content-between" key={`${ord.game}-${ord.id}`}>
                    <div className="order-type">{ord.name}</div>
                    <div className="order-pay">
                      ￥<span className="cost ml-1">{ord.price}</span>{payText.price}
                    </div>
                  </li>
                ))}
              </ul>
              <div className="info">
                {/* <div className="discount d-flex justify-content-between order-item">
                  <div className="tag">{t('discount')}</div>
                  <div className="order-pay">￥&nbsp;<span className="cost">2000</span>元/月</div>
                </div> */}
                <div className="sum d-flex justify-content-between align-items-center">
                  <div className="tag">{t('sum')}</div>
                  <div className="order-cost text-center">
                    ￥
                    <span className="cost">{Object.values(orderItems).reduce((acc, cur) => (Number(cur.price) + acc), 0)}</span>
                    {payText.price}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isScan
            ? (
              <div className="pay-content text-center">
                <h3>{payText.scan}</h3>
                {payment === 0 ? (
                  <img
                    className="qr_code"
                    src={qrCode && `data:image/png;base64,${qrCode}`}
                    alt=" "
                  />) :
                  <div>
                    <p>{payText.alipaying}</p>
                    <a href={qrCode}>{payText.problem}</a>
                  </div>}
              </div>)
            : (
              <div className="pay-content text-center">
                <p className="text-left">{t('payment')}</p>
                <div className="pay-list">
                  <ul className="pay-way d-flex justify-content-center">
                    <li
                      className={`pay-item mr-4 ${payment === 0 ? 'active' : ''}`}
                      onClick={() => this.switchType('payment', 0)}
                    >
                      <img className="mr-2" style={{ verticalAlign: '-9px' }} src={constant.wechat} alt="" />
                      {t('wechat')}
                    </li>
                    <li
                      className={`pay-item mr-4 ${payment === 1 ? 'active' : ''}`}
                      onClick={() => this.switchType('payment', 1)}
                    >
                      <img className="mr-2" style={{ verticalAlign: '-9px' }} src={constant.alipay} alt="" />
                      {t('ali')}
                    </li>
                  </ul>
                  <p className="agreement">
                    <i
                      className={`check d-inline-block mr-2 ${accept ? 'active' : ''}`}
                      onClick={() => this.switchType('accept', !accept)}
                    />
                    <span>{t('price.agreement.agree')}<a href={getLink('/contract')} target="_blank" className="filename ml-1">《{t('price.agreement.file')}》</a></span>
                  </p>
                  <div
                    className={className('web-button buy-btn btn btn-primary', { gray: !accept || !product_ids })}
                    onClick={() => this.handleSubmit()}
                  >{t('price.agreement.buy')}
                  </div>
                  {locale === 'en' && <p className="mt-4 text-muted">If you do not have the above two payment methods, you could <a href="mailto:info@owlscore.com">contact us</a>.</p>}
                </div>
              </div>)}
        </OContent>
      </Layout>
    );
  }
}

export default withI18next({ ns: 'common' })(Order);
