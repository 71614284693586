import React from 'react';
import styled from 'styled-components';
import className from 'classnames';

const AlertContainer = styled.div`
  position: fixed !important;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999;
  background-color: #fff;
  height: auto;
  line-height: 40px;
  min-width: 280px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  text-align: center;
  animation: show .5s ease-in-out 0s;
  @keyframes show {
    from {
      top: 0;
      opacity: 0;
    }
    to {
      top: 50px;
      opacity: 1;
    }
  }
`;

// class Alert extends React.Component {
//   state = {
//     display: true,
//   }
//   componentDidMount() {
//     this.fade();
//   }
//   componentDidUpdate(preProps) {
//     console.log('更新啦！')
//     if (preProps !== this.props) {
//       this.setState({
//         display: true,
//       }, () => this.fade())
//     }
//   }
//   fade() {
//     setTimeout(() => {
//       this.setState({
//         display: false,
//       });
//     }, 2000)
//   }
//   render() {
//     const { info } = this.props;
//     const { display } = this.state;
//     return (
//       <AlertContainer className={className(`alert alert-${info.type}`, { hide: !display })} >
//         {info.message}
//       </AlertContainer>
//     );
//   }
// }

function Alert({ info }) {
  const { message, type } = info;
  return (
    <AlertContainer className={className(`alert alert-${type}`)} >
      {message}
    </AlertContainer>
  );
}

export default Alert;